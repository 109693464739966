<template>
  <v-dialog
    v-model="showLandingDialog"
    @input="close"
    max-width="600px">
    <v-card style="border-radius: 30px !important;" min-height="400px">
      <v-row no-gutters align="center">

        <!-- Title + Close icon -->
        <v-col cols="12" class="mt-6">
          <v-row no-gutters>
            <v-col cols="8" class="offset-2">
              <h3 class="font-weight-bold text-center" style="margin:0 auto !important;">{{$t('view.landingDialog.title')}}</h3>
            </v-col>
            <v-col cols="1" class="offset-1">
              <v-btn icon @click="close">
                <v-icon color="primary">fal fa-times</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <!-- Icon map -->
        <v-col cols="12" class="mt-3">
          <p class="text-center" style="font-size: 30px !important;">🗺️</p>
        </v-col>

        <!-- Texts -->
        <v-col cols="12" class="mt-0">
          <p class="text-center">
            <span class="font-weight-bold">{{$t('view.landingDialog.description1')}}</span><br />
            <span>{{$t('view.landingDialog.description2')}}</span>
          </p>
        </v-col>

        <!-- Transports -->
        <v-col cols="12">
          <v-row no-gutters class="justify-center d-flex" >
            <template v-for="(transport) of transports">
              <v-col style="margin-top: 5px" class="mx-4">
                <IconModeLanding
                  :mode="transport"/>
              </v-col>
            </template>
          </v-row>
        </v-col>

        <!-- Input -->
        <v-col cols="12" class="mt-12">
          <v-row no-gutters align="center">
            <p class="font-weight-bold mx-auto">{{$t('view.landingDialog.input-text')}}</p>
            <v-col cols="6" class="offset-3">
              <v-row no-gutters align="center">
                <!--v-text-field
                  v-model="search"
                  outlined
                  dense
                  hide-details>
                </v-text-field-->
                <SearchBar
                  :value="null"
                  @change="onSearch"
                />

                <v-btn icon @click="goToMyPosition">
                  <v-icon >fas fa-location-arrow</v-icon>
                </v-btn>
              </v-row>

            </v-col>
          </v-row>
        </v-col>

        <!-- Button -->
        <v-col cols="12" class="mt-8 my-10 text-center">
          <v-btn
            @click="goLanding"
            rounded
            width="150"
            color="primary"
            class="font-weight-bold"
            style="border-radius: 10px !important;font-size: 16px !important;">
            GO
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import modes from "../../../constants/modes.json"

export default {
  name: "LandingDialog",

  components: {
    SearchBar: () => import("@/components/Common/Controls/SearchBarLanding"),
    IconModeLanding: () => import("@/components/Common/IconModeLanding"),
  },

  data() {
    return {
      modes,
      search: null
    }
  },

  methods: {
    close() {
      return this.$store.commit("setShowLandingDialog", false);
    },

    goToMyPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.showLandingDialog = false;
          this.$router.replace({
            query: {
              ...this.$route.query,
              coords: `${position.coords.latitude},${position.coords.longitude}`,
              zoom: 15
            }
          });
        });
      }
      else {
        this.$store.commit("toast/showError", this.$t('view.geolocation.error'));
      }
    },

    onSearch(val) {
      this.search = val;
    },
    goLanding() {
      if (this.search) {
        if (this.$route.name === "Home") {
          this.$router.replace({query: {q: this.search.label, coords: `${this.search.coords.lat},${this.search.coords.lng}`, zoom: 14}});

        } else
          this.$router.push({name: 'Home', query: {coords: `${this.search.coords.lat},${this.search.coords.lng}`, zoom: 14}});

      }
    }
  },

  computed: {
    showLandingDialog: {
      get() {
        return this.$store.getters['showLandingDialog'];
      },
      set(val) {
        return this.$store.commit("setShowLandingDialog", val);
      }
    },

    transports() {
      return this.modes.filter(el => el.category === 'transports');
    },

  }
}
</script>

<style scoped>
</style>